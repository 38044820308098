window.addEventListener('scroll', BackToTopShow);
let backToTopBtn = document.querySelector('.act-back-to-top')

BackToTopShow();

function BackToTopShow(){
    //console.log(window.scrollY);
    if(backToTopBtn){
        if(window.scrollY > 100){
            backToTopBtn.classList.remove('is-hidden');
        }else{
            backToTopBtn.classList.add('is-hidden');
        }
    }
}

if(backToTopBtn){
    backToTopBtn.addEventListener('click',ScrollToTop)
}
function ScrollToTop(e){
    e.preventDefault();
    window.scrollTo({top: 0, behavior: 'smooth'});
    return false;
}